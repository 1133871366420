import Swiper from 'swiper/bundle';

import 'swiper/swiper-bundle.min.css';
import MicroModal from 'micromodal';
require('../css/main.scss')

class LandingPage {
  constructor() {
    this.getHowTo();
    // this.setBusinessSlider();
    this.getBusinessSliderData();
    this.getInputValue();
    MicroModal.init();
  }

  getHowTo() {
    const howToItem = document.querySelectorAll('.js-how-to-item');
    const howToItemImg = document.querySelector('.js-how-to-image');
    const howToItemTitle = document.querySelector('.js-how-to-title');
    const howToItemDescription = document.querySelector('.js-how-to-description');

    howToItem.forEach(item => {
      item.addEventListener('click', event => {
        howToItem.forEach(function (el) {
          el.classList.remove("how-to__right-item--active");
        });
        item.classList.add("how-to__right-item--active");
        howToItemImg.setAttribute('src', item.getAttribute('data-big-image'));
        howToItemTitle.innerHTML = item.getAttribute('data-title');
        howToItemDescription.innerHTML = item.getAttribute('data-description');
      })
    })
  }

  getBusinessSliderData() {
    var requestOptions = {
      method: 'GET',
    };

    fetch("https://api.mordepo.com/api/ShowCasePartner/GetAllCompaniesWithMedicinesCount", requestOptions)
      .then(response => response.json())
      .then((results) => {
        console.log(results);
        let swiperContainer = ''
        results.forEach(function(item){
          swiperContainer += `
          <li>
            <a href="https://store.mordepo.com/login" class="swiper-slide business-partner__item-box">
              <div class="business-partner__image-box">
                <img src="https://mordepoblob.blob.core.windows.net/mordepo-public/${item.imageId}" alt="Mordepo" />
              </div>

              <div class="business-partner__text-box">
                <div class="business-partner__partner-name">
                  ${item.title}
                </div>
                <div class="business-partner__partner-count">
                  ${item.count} ürün
                </div>
              </div>
            </a>
          </li>
      `;
        });

        console.log(swiperContainer);
        document.querySelector('.js-swiper-wrapper').innerHTML = swiperContainer;

        this.setBusinessSlider();
      });
  }



  setBusinessSlider() {
    var swiper = new Swiper('.swiper-container', {
      slidesPerView: 3,
      slidesPerColumn: 2,
      spaceBetween: 30,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
  }

  getInputValue() {
    document.querySelectorAll(".js-signup-btn").forEach(box =>
      box.addEventListener("click", () => {
        window.location.href = "https://store.mordepo.com/signup?GLN=" + box.previousElementSibling.value;
      })
    )
  }
}

document.addEventListener('DOMContentLoaded', () => {
  new LandingPage();
});
